<div class="analytics-container">
  <div class="stats">
    <div class="stat-item">
      <span class="stat-value">{{ (hotel$ | async)?.completeness_percentage }}%</span>
      <span class="stat-label">COMPLETED</span>
    </div>
    <div class="stat-item">
      <span class="stat-value">{{ (hotel$ | async)?.totalNumberOfNights?.roomsNightsCount?.reduced || 0 }}</span>
      <span class="stat-label">50% ROOM NIGHTS</span>
    </div>
    <div class="stat-item">
      <span class="stat-value">{{ (hotel$ | async)?.totalNumberOfNights?.roomsNightsCount?.reduced_30 || 0 }}</span>
      <span class="stat-label">30% ROOM NIGHTS</span>
    </div>
    <div class="stat-item">
      <span class="stat-value">{{ (hotel$ | async)?.totalNumberOfNights?.roomsNightsCount?.reduced_10 || 0 }}</span>
      <span class="stat-label">10% ROOM NIGHTS</span>
    </div>
    <div class="stat-item">
      <span class="stat-value">{{ (hotel$ | async)?.bookings || 0 }}</span>
      <span class="stat-label">BOOKINGS</span>
    </div>
    <div class="stat-item">
      <span class="stat-value">{{ (hotel$ | async)?.cancelledBookings || 0 }}</span>
      <span class="stat-label">CANCELLED BOOKINGS</span>
    </div>
  </div>
</div>
