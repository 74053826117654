<!-- start page title -->
<app-pagetitle title="Room types" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="mt-4">
  <div class="row" cdkDropList (cdkDropListDropped)="onDrop($event)">
    <!-- First Card: Add Room Type -->
    <div class="col-md-12">
      <div class="card add-room-card text-center">
        <div class="card-body add-room-text">
          <i class="fas fa-plus" style="font-size: 32px;color: #f74d4d"></i>
          <p class="card-title mt-2">Add room type</p>
        </div>
      </div>
    </div>

    <!-- Second Card: Image and Content -->
    <div class="col-md-12" *ngFor="let room of rooms; let index;" cdkDrag>
      <div class="card">
        <div class="row g-0">
          <div class="col-3">
            <img src="{{ room.image }}" class="img-fluid rounded-start" alt="Room Image 1" />
          </div>
          <div class="col-9 d-flex flex-column justify-content-start mt-4">
            <div class="card-body">
              <h5 class="card-title">{{ room.name }}</h5>
              <p class="card-text">Base price: {{ room.base_price }} {{ room.currency }}</p>
              <p class="card-text">Amount of rooms: {{ room.amount }}</p>
            </div>
          </div>
        </div>
        <div class="mt-2 position-absolute" style="top: 15px; right: 15px;">
          <!-- Drag Icon -->
          <i class="fa-solid fa-up-down-left-right text-secondary fs-3 cursor-move"></i>
        </div>
        <button class="btn text-secondary position-absolute" style="bottom: 10px; right: 50px;">
          Delete
        </button>
        <button class="btn text-secondary position-absolute" style="bottom: 10px; right: 10px;"></button>
        <button class="btn text-secondary position-absolute" style="bottom: 10px; right: 10px;" (click)="editRoom(room.id)">
          Edit
        </button>
      </div>
    </div>
  </div>
</div>