import { ContactInfo } from "../../core/interfaces/contact-info";

export const CONTACT: ContactInfo[] = [
  {
    id: 1,
    label: 'CONTACTINFO.MALE',
  },
  {
    id: 2,
    label: 'CONTACTINFO.FEMALE',
  },
];

