<!-- start page title -->
<app-pagetitle title="Hotel Information" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form #hotelForm="ngForm" (ngSubmit)="onSubmit(hotelForm)">
  <div class="row mt-3">
    <div class="col-xl-12">
      <div class="card card-h-100">
          <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="name">Hotel Name *</label>
                        <input type="text" class="form-control form-input-bg" id="name" name="name" [(ngModel)]="hotel.name" #name="ngModel" required>
                        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                            <div *ngIf="name.errors?.['required']">Hotel name is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="starrating">Star Rating *</label>
                        <select class="form-select form-input-bg" id="rating" name="rating" [(ngModel)]="hotel.rating" #rating="ngModel" required>
                            <option *ngFor="let rate of ratingOptions" [value]="rate.value">{{rate.name}}</option>
                        </select>
                        <div *ngIf="rating.invalid && (rating.dirty || rating.touched)" class="text-danger">
                            <div *ngIf="rating.errors?.['required']">Star Rating is required.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country *</label>
                        <select class="form-select form-input-bg" id="country" name="country" [(ngModel)]="hotel.country_id" #country="ngModel" (change)="onCountryChange($event)" required>
                            <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
                        </select>
                        <div *ngIf="country.invalid && (country.dirty || country.touched)" class="text-danger">
                            <div *ngIf="country.errors?.['required']">Country is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="currency">Currency *</label>
                        <input type="text" class="form-control form-input-bg" id="currency" name="currency" [(ngModel)]="hotel.currency" #currency="ngModel" required>
                        <div *ngIf="currency.invalid && (currency.dirty || currency.touched)" class="text-danger">
                            <div *ngIf="currency.errors?.['required']">Currency is required.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="region">Region</label>
                        <select class="form-select form-input-bg" id="region" name="region" [(ngModel)]="hotel.region_id" #region="ngModel">
                            <option value="">Select</option>
                            <option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="town">Town *</label>
                        <input type="text" class="form-control form-input-bg" id="town" name="town" [(ngModel)]="hotel.city" #town="ngModel" required>
                        <div *ngIf="town.invalid && (town.dirty || town.touched)" class="text-danger">
                            <div *ngIf="town.errors?.['required']">Town is required.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="mb-3">
                        <label class="form-label" for="street">Street</label>
                        <input type="text" class="form-control form-input-bg" id="street" name="street" [(ngModel)]="hotel.street" #street="ngModel" required>
                        <div *ngIf="street.invalid && (street.dirty || street.touched)" class="text-danger">
                            <div *ngIf="street.errors?.['required']">Street is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="mb-3">
                        <label class="form-label" for="house_number">House Number</label>
                        <input type="text" class="form-control form-input-bg" id="house_number" name="house_number" [(ngModel)]="hotel.number" #house_number="ngModel" required>
                        <div *ngIf="house_number.invalid && (house_number.dirty || house_number.touched)" class="text-danger">
                            <div *ngIf="house_number.errors?.['required']">House Number is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="mb-3">
                        <label class="form-label" for="postal_code">Postcode *</label>
                        <input type="text" class="form-control form-input-bg" id="postal_code" name="postal_code" [(ngModel)]="hotel.postal_code" #postal_code="ngModel" required>
                        <div *ngIf="postal_code.invalid && (postal_code.dirty || postal_code.touched)" class="text-danger">
                            <div *ngIf="postal_code.errors?.['required']">Postcode is required.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="website">Website</label>
                        <input type="text" class="form-control form-input-bg" id="website" name="website" [(ngModel)]="hotel.website" #website="ngModel">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="hotel_type">Hotel Type</label>
                        <select class="form-select form-input-bg" id="hotel_type" name="hotel_type" [(ngModel)]="hotel.hotel_type" #hotel_type="ngModel" required>
                            <option *ngFor="let type of hotelType" [value]="hotel_type.value" [selected]="hotel.hotel_type == type.id">{{type.value}}</option>
                        </select>
                        <div *ngIf="hotel_type.invalid && (hotel_type.dirty || hotel_type.touched)" class="text-danger">
                            <div *ngIf="hotel_type.errors?.['required']">Hotel Type is required.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="mb-3">
                        <label class="form-label" for="checkin_from">Check-in from</label>
                        <input type="time" class="form-control form-input-bg" id="checkin_from" name="checkin_from" [(ngModel)]="hotel.checkin_from" #checkinfrom="ngModel">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="mb-3">
                        <label class="form-label" for="checkin_till">Check-in until</label>
                        <input type="time" class="form-control form-input-bg" id="checkin_till" name="checkin_till" [(ngModel)]="hotel.checkin_till" #checkintill="ngModel">
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="mb-3">
                        <br/>
                        <label> OR </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <br/>
                    <input class="form-check-input" type="checkbox" id="checkin_full_time" name="checkin_full_time" [(ngModel)]="hotel.checkin_full_time">
                    <label class="form-check-label" for="checkin_full_time">
                      &nbsp; Open till late
                  </label>
              </div>
              <div class="col-md-2">
                  <div class="mb-3">
                      <label class="form-label" for="checkout_till">Check-out until</label>
                      <input type="time" class="form-control form-input-bg" id="checkout_till" name="checkout_till" [(ngModel)]="hotel.checkout_till" #checkout="ngModel">
                  </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <br/>
                        <input class="form-check-input" type="checkbox" id="phone_requests" name="phone_requests" [(ngModel)]="hotel.phone_requests" #phone_requests="ngModel">
                        <label class="form-check-label">&nbsp; No phone requests</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="availability">Close availability at this time on the day of arrival</label>
                        <input type="time" class="form-control form-input-bg" id="availability_close_time" name="availability_close_time" [(ngModel)]="hotel.availability_close_time" #availability_close_time="ngModel">
                    </div>
                </div>
            </div>

          </div><!-- end card body -->
      </div><!-- end card -->
    </div><!-- end col -->
  </div><!-- end row -->
  <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">Hotel description</h4>
      </div><!-- end card header -->
      <div class="card-body">
          <!-- Nav tabs -->
          <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-justified">
            <li [ngbNavItem]="language.key" class="nav-item" *ngFor="let language of languages">
              <a class="nav-link"
                 ngbNavLink
                 [class.active]="language.code === selectedLanguage"
                 (click)="selectLanguage(language.code)">
                {{ language.name }}
              </a>
            </li>
          </ul>
          <div class="tab-content p-3 text-muted">
            <div *ngFor="let language of languages"
                 [class.tab-pane]="true"
                 [class.fade]="true"
                 [class.show]="language.code === selectedLanguage"
                 [class.active]="language.code === selectedLanguage">
              <textarea class="form-control form-input-bg"
                        name="description_{{ language.code }}"
                        [(ngModel)]="hotel.description[language.code]"
                        style="height: 200px"
                        placeholder="{{ language.name }} Hotel description"
                        required></textarea>
            </div>
          </div>

      </div> <!-- end card body -->
  </div>
  <div class="card">
    <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">Hotel secondary description</h4>
    </div><!-- end card header -->
    <div class="card-body">
        <!-- Nav tabs -->
        <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-justified">
            <li [ngbNavItem]="language.key" class="nav-item" *ngFor="let language of languages">
              <a class="nav-link"
                 ngbNavLink
                 [class.active]="language.code === selectedLanguage"
                 (click)="selectLanguage(language.code)">
                {{ language.name }}
              </a>
            </li>
          </ul>

          <div class="tab-content p-3 text-muted">
            <div *ngFor="let language of languages"
                 [class.tab-pane]="true"
                 [class.fade]="true"
                 [class.show]="language.code === selectedLanguage"
                 [class.active]="language.code === selectedLanguage">
              <textarea class="form-control form-input-bg"
                        name="secondary_description_{{ language.code }}"
                        [(ngModel)]="hotel.secondary_description[language.code]"
                        style="height: 200px"
                        placeholder="{{ language.name }} Hotel Secondary Description"
                        required></textarea>
            </div>
          </div>
    </div><!-- end crad body -->
  </div>
  <div class="mt-4">
    <button type="submit" class="btn btn-primary submit_btn w-md" [disabled]="hotelForm.invalid">Submit</button>
  </div>
  <br>
  <div class="card-body" *ngIf="success">
    <div class="col-xl-4">
        <div>
            <ngb-alert [dismissible]="false" type="success">
              <i class="uil uil-check me-2"></i>
                Saved successfully
            </ngb-alert>
        </div>
    </div>
  </div>
  <div class="card-body" *!ngIf="success">
    <div class="col-xl-4">
        <div>
            <ngb-alert [dismissible]="false" type="danger">
              <i class="uil uil-exclamation-octagon me-2"></i>
                Failed
            </ngb-alert>
        </div>
    </div>
  </div>
</form>
