export class Profile {
  id!: number;
  user_id!: number;
  avatar!: string | null;
  locale!: string;
  birthday!: string;
  created_at!: string;
  updated_at!: string;
  first_name!: string;
  last_name!: string;
  newsletter_subscribed!: number;
  deleted_at!: string | null;
  company!: string | null;
  phone!: string;
  weekly_hoteltips_subscribed!: number;
  new_hotels_subscribed!: number;
  salutation!: string;
  call_subscribed!: number;
}
