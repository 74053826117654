import { Component, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { Lightbox } from 'ngx-lightbox';
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-hotel-gallery',
  templateUrl: './hotel-gallery.component.html',
  styleUrl: './hotel-gallery.component.scss'
})
export class HotelGalleryComponent implements OnInit{
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  galleryFilter = 'all';
  public imageGrid: any = {};
  public config: DropzoneConfigInterface = {
    clickable: true,
    autoReset: null,
    errorReset: null
  };
  cards: Array<any> = [];
  event: any;
  mainImageId: number | null = null; // ID of the currently selected main image
  private cardIndexToDelete: number | null = null;
  private index: any = {};

  constructor(private hotelService: HotelService,
    private modalService: NgbModal,
    private router: Router) {
  }

  ngOnInit(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      this.hotel = hotel;
      if (this.hotel.images) {
        this.imageGrid = this.hotel.images;
        console.log(this.imageGrid);
        // Set the first image as the main image by default
        if (this.imageGrid.length > 0) {
          this.mainImageId = this.imageGrid[0].id;
        }
      }
    });
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel gallery', active: true }
    ];
  }
  /***
   * Active all Products Grid selected
   */
  activeCategory(category: string): void {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.imageGrid = this.hotel.images;
    } else {
      this.imageGrid = this.hotel.images.filter((x: any) => x.season_image === this.galleryFilter);
    }
  }
  // Method to open the delete confirmation modal
  openDeleteModal(deleteImageModal:any, index: number): void {
    this.cardIndexToDelete = deleteImageModal;
    this.modalService.open(deleteImageModal, { backdrop: 'static', keyboard: false, centered: true });
    this.index = index;
    console.log(index);
  }
  // Method to confirm the deletion of a card
  confirmDelete(modal: any): void {
    if (this.hotel.images && this.hotel.images.length > 0) {
      if (this.cardIndexToDelete !== null) {
        this.imageGrid.splice(this.index, 1);
        this.hotelService.deleteSelectedImage(this.hotel.id, this.hotel.images[this.index].id).subscribe(result1 => {
        });
      }
      this.cardIndexToDelete = null;
      modal.close();
    }
  }

  //  onMainImageToggle(imageId: number): void {
  //   this.mainImageId = this.mainImageId === imageId ? null : imageId;
  // }
  onMainImageToggle(event: any, index: number) {
    if (event.checked) {
      this.hotel.images[index].is_main = 1;
    } else {
      this.hotel.images[index].is_main = 0;
    }
    const season = this.hotel.images[index].season_image;
    this.hotel.images.forEach((image:any, imageId: number) => {
      if (imageId !== index && season === 'general') {
        image.is_main = 0;
      }
      if (imageId !== index && (season === 'summer' || season === 'winter') && image.season_image === 'general') {
        image.is_main = 0;
      }
      if (imageId !== index && image.season_image === season) {
        image.is_main = 0;
      }
    });
  }
  onGalleryDrop(event: CdkDragDrop<any[]>): void {
    const previousIndex = this.imageGrid.findIndex((image:any) => image === event.item.data);
    const currentIndex = event.currentIndex;
    const movedItem = this.imageGrid[previousIndex];

    // Remove the item from its previous position
    this.imageGrid.splice(previousIndex, 1);

    // Insert the item at its new position
    this.imageGrid.splice(currentIndex, 0, movedItem);
  }
  onSeasonabilityChange(index: number, imageId: number, newSeasonImage: string): void {
    this.imageGrid[index].season_image = newSeasonImage;
  }
}
