import { Component, OnInit } from '@angular/core';
import { RoomService } from '../../core/services/room.service';
import { Room } from '../../core/interfaces/room';
import { Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrl: './rooms.component.scss'
})
export class RoomsComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  public rooms: any = {};
  constructor(
    private roomService: RoomService,
    private router: Router
  ) {

  }
  ngOnInit(): void {
    this.subscribeToSelectedHotel();
    this.initializeBreadcrumbs();

  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Rooms' },
      { label: 'Room types', active: true }
    ];
  }
  subscribeToSelectedHotel(): void {
    this.roomService.getAllRooms().subscribe((rooms: Room | null) => {
      if (rooms) {
        this.rooms = rooms;
        console.log(this.rooms);
      }
    });
  }
  onDrop(event: CdkDragDrop<string[]>) {
    const previousIndex = this.rooms.findIndex((room:any) => room === event.item.data);
    const currentIndex = event.currentIndex;

    // Move the room to the new position
    const movedRoom = this.rooms.splice(previousIndex, 1)[0];
    this.rooms.splice(currentIndex, 0, movedRoom);
  }
  editRoom(roomId: number) {
    this.router.navigate(['/room-edit', roomId]);
  }
}
