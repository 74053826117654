<div class="dialog-container">
    <div class="dialog-header">
      <h1 class="dialog-title">Select a Hotel</h1>
    </div>
    <div class="search-bar">
      <mat-form-field appearance="outline">
        <mat-label>Search Hotels</mat-label>
        <input matInput (input)="applyFilter($event)" placeholder="Type to search...">
      </mat-form-field>
    </div>
    <div class="dialog-content" (scroll)="onScroll($event)">
      <div class="hotel-list">
        <div class="hotel-card" *ngFor="let hotel of filteredHotels" (click)="onSelect(hotel.id.toString())">
          <mat-card>
            <img mat-card-image [src]="hotel.image || 'assets/images/image_placeholder.jpg'" (error)="onImageError($event)" alt="{{ hotel.name }}">
            <mat-card-content>
              <h2 class="hotel-name">{{ hotel.name }}</h2>
              <div class="hotel-info">
                <div class="stars" *ngIf="hotel.rating !== 'no_rating'">
                  <mat-icon *ngFor="let star of [].constructor(parseInt(hotel.rating, 10))">star</mat-icon>
                </div>
                <div class="completeness">
                  <mat-progress-spinner
                    [value]="hotel.completeness_percentage"
                    diameter="30" strokeWidth="3">
                  </mat-progress-spinner>
                  <span class="percentage-text">{{ hotel.completeness_percentage }}%</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="loading-spinner" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="dialog-footer">
      <button mat-button (click)="close()">Cancel</button>
    </div>
  </div>
