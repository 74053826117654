import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoomService } from '../../../core/services/room.service';
import { Room } from '../../../core/interfaces/room';

@Component({
  selector: 'app-room-edit',
  templateUrl: './room-edit.component.html',
  styleUrl: './room-edit.component.scss'
})
export class RoomEditComponent implements OnInit {
  roomId: any;
  public room: any; // Define your room type properly

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private roomService: RoomService
  ) { }

  ngOnInit(): void {
    this.roomId = this.route.snapshot.paramMap.get('id');
    this.fetchRoomDetails(this.roomId);
  }

  fetchRoomDetails(id: number): void {
    this.roomService.getRoomDetails(id).subscribe((response) => {
      if (response) {
        this.room = response.data;
        console.log(this.room);
      }
    });
  }

  onSubmit() {
    this.router.navigate(['/rooms']);
  }
}
