<div class="container-fluid mt-3">
    <!-- start page title -->
    <app-pagetitle title="Rooms" class="rooms-edit-screen"></app-pagetitle>
    <!-- end page title -->
    <button type="submit" class="btn btn-primary submit_btn position-absolute add-room-btn cursor-pointer">Add room &nbsp;<i class="fas fa-plus fa-sm"></i>
    </button>
    <div class="card mt-2">
        <div class="card-body">
            <form (ngSubmit)="onSubmit()" #roomForm="ngForm">
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-3">Room Description</label>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex mb-2">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Duetsch</span><input type="email" class="form-control form-input-bg text-room-desc" id="formrow-email-input" value="{{ room.name.de }}" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description"
                                        placeholder="Room description"
                                        required> {{ room.description.de }}
                                    </textarea>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Francais</span><input type="email" class="form-control form-input-bg text-room-desc" id="formrow-email-input" value="{{ room.name.fr }}" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                            name="description"
                                            placeholder="Room description"
                                            required>{{ room.description.fr }}
                                    </textarea>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">English</span><input type="email" class="form-control form-input-bg text-room-desc" id="formrow-email-input" value="{{ room.name.en }}" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description"
                                        placeholder="Room description"
                                        required> {{ room.description.en }}
                                    </textarea>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Italiano</span><input type="email" class="form-control form-input-bg text-room-desc" id="formrow-email-input" value="{{ room.name.it }}" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description"
                                        placeholder="Room description"
                                        required> {{ room.description.it }}
                                    </textarea>
                                </div>
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div>
                    <div class="col-1">
                        <button type="button" class="btn btn-link p-0">
                            <i class="fas fa-trash-alt delete-icon-danger"></i> <!-- Red delete icon -->
                        </button>
                        <img src="assets/images/drag.png" alt="drag-icon" class="img-fluid ms-2">
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-3">Room Size</label>
                    <div class="col-9">
                        <input type="email" class="form-control form-input-bg text-room-desc text-room-size" id="formrow-email-input" value="{{ room.average_room_size }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;m²" required>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-3">Amenities</label>
                    <div class="col-9">
                        <form class="row gx-3 gy-2 align-items-center" *ngFor="let features of room.features; let index;">
                            <div class="col-auto">
                                <div class="form-check text-room-desc">
                                    <input class="form-check-input cursor-pointer" type="checkbox" id="autoSizingCheck1" checked>
                                    <label class="form-check-label cursor-pointer" for="autoSizingCheck1">
                                      {{ features.name }}
                                    </label>
                                </div>
                            </div>
                        </form><!-- end form -->
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-3">Availability</label>
                    <div class="col-9">
                        <span class="text-room-color">Rooms per day on HotelCard</span>
                        <span class="d-flex">
                            <input class="form-control form-input-bg availability-desc cursor-pointer" type="number" value="{{room.amount}}" id="example-number-input">
                            <span class="form-check text-room-desc ms-5">
                                <input class="form-check-input cursor-pointer" type="checkbox" id="autoSizingCheckHide" [checked]="room.hide">
                                <label class="form-check-label cursor-pointer" for="autoSizingCheckHide">
                                    Hide room
                                </label>
                            </span>
                        </span>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-3">Occupancy</label>
                    <div class="col-9">
                        <span class="text-room-color">Max no of adults</span>
                        <span class="d-flex">
                            <input class="form-control form-input-bg occupancy-desc cursor-pointer" type="number" value="{{room.max_people_allowed}}" id="example-number-input">
                            <div class="form-check text-room-desc ms-5">
                                <input class="form-check-input cursor-pointer" type="checkbox" id="autoSizingCheckFamily" [checked]="room.is_family_room">
                                <label class="form-check-label cursor-pointer" for="autoSizingCheckFamily">
                                    Family Room
                                </label>
                            </div>
                        </span>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-3">Extra Beds</label>
                    <div class="col-9">
                        <select class="form-select extra-bed form-input-bg text-room-desc cursor-pointer">
                            <option>Select the bed</option>
                            <option>1</option>
                            <option>2</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-3">Base Price</label>
                    <div class="col-9">
                        <input type="email" class="form-control form-input-bg text-room-desc text-room-size" id="formrow-email-input" value="{{room.base_price}}" required>
                        <div class="form-check form-switch mb-2 mt-1 text-room-desc" dir="ltr">
                            <input type="checkbox" class="form-check-input cursor-pointer" id="customSwitchsizesm" [checked]="room.is_breakfast_included">
                            <label class="form-check-label cursor-pointer" for="customSwitchsizesm">Breakfast included</label>
                        </div>
                        <div class="form-check text-room-desc">
                            <input class="form-check-input cursor-pointer" type="checkbox" id="formCheck1" [checked]="room.allow_single_use">
                            <label class="form-check-label cursor-pointer" for="formCheck1">
                                Single use
                            </label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-3">Room images</label>
                    <div class="col-9">
                        <div>
                            <dropzone class="dropzone"></dropzone>
                        </div>
                    </div>
                </div>
                <div class="form-check form-switch mb-2 mt-1 text-room-desc position-absolute hide-room-switch" dir="ltr">
                    <label class="form-check-label mt-1 cursor-pointer" for="customHideSwitchsizesm">Hide room</label>
                    <input type="checkbox" class="form-check-input position-relative form-hide-switch cursor-pointer" id="customHideSwitchsizesm" [checked]="room.hide">
                </div>
                <button type="submit" class="btn btn-primary submit_btn float-md-end cursor-pointer">Update room details</button>

            </form>
        </div><!-- end card-body -->
    </div><!-- end card -->
  </div>
