// booking-chart-data.ts

import { ChartOptions } from "../interfaces/booking-chart-options";
import { BookingStatsService } from '../services/booking-stats.service'; // Import BookingStatsService
/**
 * Circle Chart - Custom Angle
 */
function setupCustomAngleChart(series: number[], labels: string[], bookingStatsService: BookingStatsService): ChartOptions {
  // Initialize chart options
  const customAngleChart: ChartOptions = {
    series: series,
    chart: {
      height: 340,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          }
        }
      }
    },
    colors: ['#038edc', '#5fd0f3', '#f1734f', '#51d28c'],
    labels: labels,
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 50,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };

  // Use BookingStatsService to fetch data
  bookingStatsService.getBookingStats();

  return customAngleChart;
}

/**
 * Semi Donut Chart
 */
function setupSemiDonutChart(series: number[], colors: string[], labels: string[]): ChartOptions {
  const semiDonutChart: ChartOptions = {
    series: series,
    chart: {
      height: 350,
      type: 'donut',
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'bottom',
      labels: {
        useSeriesColors: true,
      },
      formatter: function (labels, opts) {
        // Define your custom labels based on series index or other criteria
        const customLegendLabels = ['Total Bookings', 'Cancelled Bookings']; // Example custom labels array
        
        // Use opts.seriesIndex to determine which custom label to use
        const customLegendLabel = customLegendLabels[opts.seriesIndex] || labels;
        
        return customLegendLabel;
      }
    },
    colors: colors,
    labels: labels
  };

  return semiDonutChart;
}

export { setupCustomAngleChart, setupSemiDonutChart };
