import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import {FeatherModule} from "angular-feather";
import {allIcons} from "angular-feather/icons";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import { TopbarComponent } from './topbar/topbar.component';
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {SimplebarAngularModule} from "simplebar-angular";
import { SidebarComponent } from './sidebar/sidebar.component';
import {MatIconButton} from "@angular/material/button";
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    FooterComponent,
    TopbarComponent,
    SidebarComponent,
    PagetitleComponent
  ],
  imports: [
    CommonModule,
    FeatherModule.pick(allIcons),
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    FeatherModule,
    MatIconButton,
    MatIconModule
  ],
  exports: [
    FooterComponent,
    TopbarComponent,
    SidebarComponent,
    PagetitleComponent
  ]
})
export class ComponentsModule { }
