import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HotelSelectionDialogComponent } from '../../components/hotel-selection-dialog/hotel-selection-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openHotelSelectionDialog(): MatDialogRef<HotelSelectionDialogComponent> {
    return this.dialog.open(HotelSelectionDialogComponent, {
      width: '80vw',
      height: '80vh',
      backdropClass: 'transparent-backdrop',
    });
  }
}
