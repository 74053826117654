import { Component, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { CountryService } from '../../core/services/country.service';
import { Country } from '../../core/interfaces/country';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-hotel-information',
  templateUrl: './hotel-information.component.html',
  styleUrls: ['./hotel-information.component.scss']
})
export class HotelInformationComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public ratingOptions: any = {};
  public country: any = {};
  public hotelType: any = {};
  countries:Country[]=[];
  regions:Country[]=[];
  success:boolean = false;

  languages = [
    { key: 1, code: 'de', name: 'Deutsch' },
    { key: 2, code: 'en', name: 'English' },
    { key: 3, code: 'fr', name: 'Français' },
    { key: 4, code: 'it', name: 'Italiano' }
    // Add more languages as needed
  ];

  selectedLanguage = this.languages[0].code; // Default to the first language

  selectLanguage(languageCode: string) {
    this.selectedLanguage = languageCode;
  }
  constructor(private hotelService: HotelService,
    private countryService: CountryService
  )
  {
    this.ratingOptions = [
      {value: 1, name: 1},
      {value: 2, name: 2},
      {value: 3, name: 3},
      {value: 4, name: 4},
      {value: 5, name: 5},
      {value: 'no_rating', name: 'No Rating'},
      {value: 6, name: 'Swiss Lodge'}
    ];
    this.hotelType = [
      {id:'full', value:'Full year'},
      {id:'seasonal', value:'Seasonal'}
    ];
    this.languages = [
        { key: 1, code: 'de', name: 'Deutsch' },
        { key: 2, code: 'en', name: 'English' },
        { key: 3, code: 'fr', name: 'Français' },
        { key: 4, code: 'it', name: 'Italiano' }
      ];
  }

  ngOnInit(): void {
    // Initialize component
    this.subscribeToSelectedHotel();
    this.loadCountries();
    this.initializeBreadcrumbs();

  }

  loadCountries(): void {
    this.countryService.getAllCountries().subscribe(
      (data: Country[]) => {
        this.countries = data;
        this.fetchRegionsByHotelCountry();
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }

  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel information', active: true }
    ];
  }

  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.fetchRegionsByHotelCountry();
      }
    });
  }

  setDefaultCountry(): void {
    const defaultCountryIndex = this.getDefaultCountryIndex();
    if (defaultCountryIndex !== undefined) {
      this.fetchRegionsByCountry(defaultCountryIndex);
    }
  }

  setInitialCountryAndRegions(): void {
    const defaultCountryIndex = this.getDefaultCountryIndex();
    if (defaultCountryIndex !== undefined) {
      this.fetchRegionsByCountry(defaultCountryIndex);
    }
  }

  fetchRegionsByHotelCountry(): void {
    if (this.hotel.country_id) {
      const countryIndex = this.countries.findIndex(country => country.id === this.hotel.country_id);
      if (countryIndex !== -1) {
        this.fetchRegionsByCountry(countryIndex);
      }
    }
  }

  fetchRegionsByCountry(countryIndex: number): void {
    const selectedCountry = this.countries[countryIndex];
    this.countryService.getRegions(selectedCountry.id).subscribe(
      (regions: any[]) => {
        this.regions = regions;
      },
      (error) => {
        console.error(`Error fetching regions for country ${selectedCountry.name}:`, error);
      }
    );
  }
    setPhoneRequests(event: any): void{
      if (event.checked == true) {
        this.hotel.phone_requests = 1;
      } else {
        this.hotel.phone_requests = 0;
      }
    }
    getLanguages() {
      this.hotelService.getLanguages().subscribe(
        (languages: any) => {
          return this.languages = languages;
        },
        (error) => {
          console.error(`Error fetching lanuages:`, error);
        }
      );
    }
    onSubmit(form: NgForm) {
      if (form.valid) {
        console.log(form.value.description);
        const description:any = {};
    const secondary_description:any = {};

    this.languages.forEach((language:any) => {
      description[language.code] = form.value[`description_${language.code}`];
      secondary_description[language.code] = form.value[`secondary_description_${language.code}`];
    });
        // Construct the payload according to the required structure
        const payload = {
          name: form.value.name,
          rating: form.value.rating,
          country_id: form.value.country,
          currency: form.value.currency,
          region_id: form.value.region,
          city: form.value.town,
          street: form.value.street,
          number: form.value.house_number,
          postal_code: form.value.postal_code,
          website: form.value.website,
          hotel_type: form.value.hotel_type,
          checkin_from: form.value.checkin_from,
          checkin_till: form.value.checkin_till,
          checkin_full_time: form.value.checkin_full_time,
          checkout_till: form.value.checkout_till,
          phone_requests: form.value.phone_requests,
          availability_close_time: form.value.availability_close_time,
          description: description,
          secondary_description: secondary_description,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };

        // Log the payload to see if it matches the required format
        console.log('Hotel Info Payload:', payload);

        // Make the service call to save the hotel contact
        this.hotelService.saveHotel(this.hotel.id, payload).subscribe(
          (response: any) => {
            this.success = true;
            console.log("Success");
          },
          (error) => {
            this.success = false;
            console.error("Failed", error);
          }
        );
      } else {
        console.log('Form is invalid');
      }
    }

  getDefaultCountryIndex(): number | undefined {
    return this.countries.findIndex(country =>
      ['Switzerland', 'France'].includes(country.name)
    );
  }
  onCountryChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const countryIndex = this.countries.findIndex(country => country.id === +selectElement.value);
    if (countryIndex !== undefined) {
      this.fetchRegionsByCountry(countryIndex);
    }
  }
}
