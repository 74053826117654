<!-- start page title -->
<app-pagetitle title="Contact Information" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<div class="row mt-3">
    <div class="col-lg-4">
        <div class="card position-absolute z-1 fw-normal ms-3 contact-card-bg" *ngIf="showLightCard">
            <h6 class="card-header bg-light">Data Usage</h6>
            <div class="card-body">
                <p class="card-text">The contact details will be used by HotelCard only internally and are not visible on the website.</p>
            </div>
        </div>
    </div>
</div>
<!-- end page title -->
<div class="row mt-3">
    <div class="col-xl-12">
        <div class="card card-h-100">
            <div class="card-body">
                <div class="">
                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <div class="form-check form-check-inline" *ngFor="let option of radioOptions">
                                <input
                                  class="form-check-input form-input-radio form-input-bg"
                                  type="radio"
                                  name="gender"
                                  [id]="'formRadios-' + option.id"
                                  [(ngModel)]="hotel.hotel_contact.gender"
                                  [value]="option.gender"
                                  required>
                                <label class="form-check-label" [for]="'formRadios-' + option.id">
                                  {{ option.label | translate }}  <!-- This should work for all languages -->
                                </label>
                              </div>

                            <div *ngIf="contactForm.submitted && !hotel.hotel_contact.gender" class="text-danger">
                                Gender is required.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12" hidden>
                            <label class="form-label" for="name">Hotel Name *</label>
                        <input type="text" class="form-control form-input-bg" id="name" name="name" [(ngModel)]="hotel.name" hidden>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="first_name">First name *</label>
                              <input type="text" class="form-control form-input-bg" id="first_name" name="first_name" [(ngModel)]="hotel.hotel_contact.first_name" #first_name="ngModel" required>
                              <div *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)" class="text-danger">
                                <div *ngIf="first_name.errors?.['required']">First Name is required.</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="last_name">Last name *</label>
                              <input type="text" class="form-control form-input-bg" id="last_name" name="last_name" [(ngModel)]="hotel.hotel_contact.last_name" #last_name="ngModel" required>
                              <div *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)" class="text-danger">
                                <div *ngIf="last_name.errors?.['required']">Last name is required.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="role">Role</label>
                              <input type="text" class="form-control form-input-bg" id="role" name="role" [(ngModel)]="hotel.hotel_contact.role" #role="ngModel" required>
                              <div *ngIf="role.invalid && (role.dirty || role.touched)" class="text-danger">
                                <div *ngIf="role.errors?.['required']">Last name is required.</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="email">E-mail address *</label>
                              <input type="email" class="form-control form-input-bg" id="email" name="email" [(ngModel)]="hotel.hotel_contact.email" #email="ngModel" required>
                              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                                <div *ngIf="email.errors?.['required']">Last name is required.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="booking_email">Hotel Booking email *</label>
                              <input type="email" class="form-control form-input-bg" id="booking_email" name="booking_email" [(ngModel)]="hotel.booking_email" #booking_email="ngModel" required>
                              <div *ngIf="booking_email.invalid && (booking_email.dirty || booking_email.touched)" class="text-danger">
                                <div *ngIf="booking_email.errors?.['required']">Last name is required.</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="general_email">Email for general questions</label>
                              <input type="email" class="form-control form-input-bg" id="general_email" name="general_email" [(ngModel)]="hotel.general_email" #general_email="ngModel" required>
                              <div *ngIf="general_email.invalid && (general_email.dirty || general_email.touched)" class="text-danger">
                                <div *ngIf="general_email.errors?.['required']">Last name is required.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="phone_hotel">Phone Hotel(incl.international area code)</label>
                              <input type="text" class="form-control form-input-bg" id="phone_hotel" name="phone_number" [(ngModel)]="hotel_contact.phone_number" required>
                              <div *ngIf="contactForm.submitted && !hotel_contact.phone_number" class="text-danger">
                                Phone Hotel is required.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="phone_contact">Phone Contact(incl.international area code)</label>
                              <input type="text" class="form-control form-input-bg" id="phone_contact" name="phone_number" [(ngModel)]="hotel_contact.phone_number" required>
                              <div *ngIf="contactForm.submitted && !hotel_contact.phone_number" class="text-danger">
                                Phone Contact is required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <button type="submit" class="btn btn-primary submit_btn w-md">Submit</button>
                        </div>
                      </form>
                      <br>
                      <div *ngIf="success">
                        <div class="col-xl-4">
                            <div>
                                <ngb-alert [dismissible]="false" type="success">
                                  <i class="uil uil-check me-2"></i>
                                    Saved successfully
                                </ngb-alert>
                            </div>
                        </div>
                      </div>
                      <div *!ngIf="success">
                        <div class="col-xl-4">
                            <div>
                                <ngb-alert [dismissible]="false" type="danger">
                                  <i class="uil uil-exclamation-octagon me-2"></i>
                                    Failed
                                </ngb-alert>
                            </div>
                        </div>
                      </div>
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

