// access-control.service.ts
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import {Roles} from "../enums/roles";

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  constructor(private userService: UserService) {}

  private mapRole(role: string): Roles | null {
    switch (role) {
      case 'ADMIN':
        return Roles.ADMIN;
      case 'HOTEL_MANAGER':
        return Roles.HOTEL_MANAGER;
      default:
        return null;
    }
  }

  isAdmin(): boolean {
    const user = this.userService.getUser();
    return this.mapRole(user?.role) === Roles.ADMIN;
  }

  isHotelManager(): boolean {
    const user = this.userService.getUser();
    return this.mapRole(user?.role) === Roles.HOTEL_MANAGER;
  }
}
