import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { EventService } from '../../core/services/event.service';

@Component({
  selector: 'app-hotel-selection-dialog',
  templateUrl: './hotel-selection-dialog.component.html',
  styleUrls: ['./hotel-selection-dialog.component.scss']
})
export class HotelSelectionDialogComponent implements OnInit {
  protected readonly parseInt = parseInt;
  hotels: Hotel[] = [];
  filteredHotels: Hotel[] = [];
  filters = {
    page: 1,
    per_page: 24, // Load fewer hotels initially to ensure scrolling
    total: 0,
    loaded_component: false,
    loading_data: false,
    options: {
      sizes: [10, 25, 50, 100],
    },
  };
  loading = false; // Flag to prevent multiple simultaneous requests

  constructor(
    private hotelService: HotelService,
    private eventService: EventService,
    public dialogRef: MatDialogRef<HotelSelectionDialogComponent>
  ) {}

  ngOnInit(): void {
    this.loadHotels();
  }

  loadHotels(): void {
    if (this.loading) return; // Prevent multiple simultaneous requests
    this.loading = true;
    this.hotelService.getHotels(this.filters).subscribe((response) => {
      this.hotels = [...this.hotels, ...response.data]; // Append new hotels to the existing list
      this.filteredHotels = this.hotels; // Initialize filtered hotels
      this.filters.total = response.total;
      this.loading = false;
    });
  }

  onSelect(hotelId: string): void {
    const selectedHotel = this.hotels.find(hotel => hotel.id.toString() === hotelId);
    if (selectedHotel) {
      this.eventService.changeManagedHotel(selectedHotel);
      this.dialogRef.close(hotelId);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  onScroll(event: any): void {
    const element = event.target;
    if (!this.loading && element.scrollHeight - element.scrollTop <= element.clientHeight * 1.2) {
      if (this.filters.page * this.filters.per_page < this.filters.total) {
        this.filters.page++;
        this.loadHotels();
      }
    }
  }

  onImageError(event: Event): void {
    (event.target as HTMLImageElement).src = 'assets/images/image_placeholder.jpg';
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredHotels = this.hotels.filter(hotel => hotel.name.toLowerCase().includes(filterValue));
  }
}
