<!-- start page title -->
<app-pagetitle title="Payments" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="row mt-3">
  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
      <div class="card card-h-100">
        <div class="card-body">
          <a href="https://www.e-guma.ch/login/" target="_blank">
            <img class="payment-image img-fluid mx-auto" src="assets/images/payments-eguma-logo.png" alt="Eguma Logo" />
          </a>
          <h4 class="card-title">Payment vouchers </h4>
          <p class="fs-6">Guests can pay with Hotel vouchers directly in the Hotel. The vouchers are delivered by E-Guma. When someone wants to pay with vouchers, use the bellow link (and your login) to take the payment.</p>
            <a href="https://www.e-guma.ch/login/" target="_blank"
              class="btn btn-primary submit_btn w-md">Redeem voucher</a>
        </div><!-- end card body -->
      </div><!-- end card -->
  </div><!-- end col -->
</div><!-- end row -->
