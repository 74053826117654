import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  public hotel$: Observable<Hotel | null>;

  constructor(private hotelService: HotelService) {
    this.hotel$ = this.hotelService.selectedHotel$;
  }

  ngOnInit() {
    this.hotelService.getSelectedHotelObject().subscribe();
    console.log(this.hotel$);
  }
}
