import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactInformationService } from '../../core/services/contact-information.service';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})

/**
 * Page Title Component
 */
export class PagetitleComponent implements OnInit {

  @Input()
  breadcrumbItems!: Array<{
    active?: boolean;
    label?: string;
  }>;
  showCard:boolean = false;
  @Input() title: string | undefined;

  isContactPage(): boolean {
    return this.route.snapshot.url[0].path === 'contact-information'; // Example for route check
  }
  constructor(private route: ActivatedRoute,private contactInformationService: ContactInformationService) { }
  ngOnInit(): void {
  }
  hovered(isHovered: boolean): void {
    if (isHovered && this.isContactPage()) {
      this.contactInformationService.setShowLightCard(true);
    } else {
      this.contactInformationService.setShowLightCard(false);
    }
  }
}
