<!-- Page Title -->
<app-pagetitle title="Hotel Gallery" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<!-- File Upload Section -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h4 class="card-title">File upload</h4>
            </div>
            <div class="card-body">
                <div>
                    <dropzone class="dropzone" [config]="config"></dropzone>
                </div>
                <div class="text-center mt-4">
                    <button type="button" class="btn btn-primary">Send Files</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Gallery Photos Section -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h4 class="card-title">Gallery Photos</h4>
            </div>
            <div class="card-body">
                <!-- Filter Buttons -->
                <div class="text-center">
                    <ul class="list-inline categories-filter button-group" id="filter">
                        <li class="list-inline-item" (click)="activeCategory('all')">
                            <a class="categories" [ngClass]="{'active': galleryFilter === 'all'}" data-filter="*">All</a>
                        </li>
                        <li class="list-inline-item" (click)="activeCategory('general')">
                            <a class="categories" [ngClass]="{'active': galleryFilter === 'general'}" data-filter=".general">General</a>
                        </li>
                        <li class="list-inline-item" (click)="activeCategory('summer')">
                            <a class="categories" [ngClass]="{'active': galleryFilter === 'summer'}" data-filter=".summer">Summer</a>
                        </li>
                        <li class="list-inline-item" (click)="activeCategory('winter')">
                            <a class="categories" [ngClass]="{'active': galleryFilter === 'winter'}" data-filter=".winter">Winter</a>
                        </li>
                    </ul>
                </div>

                <!-- Gallery Images -->
                <div class="row gallery-wrapper" cdkDropList (cdkDropListDropped)="onGalleryDrop($event)" [cdkDropListData]="imageGrid">
                    <div *ngFor="let hotel_image of imageGrid; let i = index" class="element-item col-xl-3 col-sm-6" cdkDrag [cdkDragData]="hotel_image">
                        <div class="align-items-start card seasonal-box-shadow">
                          <i class="material-icons icon-16  mx-auto d-block text-muted px-1 py-2"  aria-hidden="true">view_module</i>
                            <div class="gallery-container">
                                <img class="gallery-img img-fluid mx-auto"
                                    *ngIf="hotel_image.file_small"
                                    [src]="hotel_image.file_small"
                                    [alt]="hotel_image.name"
                                    [ngStyle]="{
                                        'width': hotel_image.file_small === '/assets/images/hotel_placeholder.svg' ? 'auto' : '100%',
                                        'height': hotel_image.file_small === '/assets/images/hotel_placeholder.svg' ? 'auto' : '100%',
                                        'margin': hotel_image.file_small === '/assets/images/hotel_placeholder.svg' ? '10px auto' : '0',
                                        'display': hotel_image.file_small === '/assets/images/hotel_placeholder.svg' ? 'block' : 'inline'
                                    }"
                                    (error)="hotel_image.image_error = true; hotel_image.file_small = '/assets/images/hotel_placeholder.svg';" />
                            </div>
                            <div class="box-content p-2">
                                <!-- <p class="season-tab-size-btn mx-1">Seasonability</p> -->
                                <!-- Nav Tabs -->
                                <!-- <ul ngbNav #pillsJustifyNav="ngbNav" [activeId]="hotel_image.season_image === 'general' || hotel_image.season_image === 'summer' || hotel_image.season_image === 'winter'" class="nav nav-pills seasonal-tab bg-light">
                                    <li [ngbNavItem]="hotel_image.season_image === 'general'" active ="hotel_image.season_image === 'general'">
                                        <a ngbNavLink (change)="onSeasonabilityChange(i, hotel_image.id, 'general')">
                                            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                            <span class="d-none d-sm-block season-tab-size">General</span>
                                        </a>
                                    </li>
                                    <li [ngbNavItem]="hotel_image.season_image === 'summer'" active="hotel_image.season_image === 'summer'">
                                        <a ngbNavLink (change)="onSeasonabilityChange(i, hotel_image.id, 'summer')">
                                            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                                            <span class="d-none d-sm-block season-tab-size">Summer</span>
                                        </a>
                                    </li>
                                    <li [ngbNavItem] active="hotel_image.season_image === 'winter'">
                                        <a ngbNavLink (change)="onSeasonabilityChange(i, hotel_image.id, 'winter')">
                                            <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                                            <span class="d-none d-sm-block season-tab-size">Winter</span>
                                        </a>
                                    </li>
                                </ul> -->
                                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    <input type="checkbox" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [checked]="hotel_image.season_image == 'general'" (change)="onSeasonabilityChange(i,hotel_image.id,'general')">
                                    <label class="btn btn-outline-secondary seasonal-check" for="btnradio1">General</label>
            
                                    <input type="checkbox" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [checked]="hotel_image.season_image == 'summer'" (change)="onSeasonabilityChange(i,hotel_image.id,'summer')">
                                    <label class="btn btn-outline-secondary seasonal-check" for="btnradio2">Summer</label>
            
                                    <input type="checkbox" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" [checked]="hotel_image.season_image == 'winter'" (change)="onSeasonabilityChange(i,hotel_image.id,'winter')">
                                    <label class="btn btn-outline-secondary seasonal-check" for="btnradio3">Winter</label>
                                </div>
                            </div>
                            <div class="box-content mb-2">
                                <div class="form-check form-switch form-switch-md mx-3">
                                    <input class="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault{{i}}"
                                        [checked]="hotel_image.is_main == 1"
                                        (change)="onMainImageToggle($event, i);hotel_image.is_main = $event.target">
                                    <label class="form-check-label season-tab-size cursor-pointer" for="flexSwitchCheckDefault{{i}}">Set as main image</label>
                                </div>
                                <button type="button" class="btn text-muted text-uppercase season-tab-size mx-1 fw-bold"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                    (click)="openDeleteModal(deleteImageModal, i)">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Image Modal -->
<ng-template #deleteImageModal let-modal>
    <div class="modal-header delete-modal-header">
        <h5 class="modal-title mt-0">Delete Image</h5>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to delete?</p>
    </div>
    <div class="modal-footer delete-modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary submit_btn" (click)="confirmDelete(modal)">Okay</button>
    </div>
</ng-template>
