import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpService } from './http.service';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactInformationService {
  private showLightCardSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpService) { }

  setShowLightCard(show: boolean): void {
    this.showLightCardSubject.next(show);
  }

  getShowLightCard(): Observable<boolean> {
    return this.showLightCardSubject.asObservable();
  }
  saveHotelContact(id: string | number, data: any){
    return this.http.put(`hotelsV2/contact/${id}`, data).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel contact', error);
        return of(null);
      })
    );
  }
}
