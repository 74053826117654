import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(
    private http: HttpService
  ) {

  }
  getUsedCountries() {
    return this.http.get('countries/used').pipe(
      map(result => {
      if (!result || result.error) {
        return [];
      }
      return result.data;
    })
  );
  }
  getAllCountries() {
    return this.http.get('countries').pipe(
      map(response => {
        if (response && response.data) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }
  getRegions(country: string | number) {
    return this.http.get(`countries/${country}/regions`).pipe(
      map(result => {
        if (!result || result.error || result.errors) {
          if (country === 254) {
            return [];
          }
          return [];
        }
        return result.data || [];
      }),
      catchError(error => {
        console.error('Error fetching regions:', error);
        return []; // Return an empty array or handle the error as needed
      })
    );
  }
  getLanguages() {
    return this.http.get('configs/languages').pipe(
      map(response => {
        if (response && response.data) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }
}
