import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {SortEvent} from "../interfaces/sort-event";
export type SortDirection = 'asc' | 'desc' | '';
@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class BookingListSortableDirective {
  @Input() sortable: string = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();
  rotate() {
    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}