<!-- start page title -->
<app-pagetitle title="Bookings" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row mt-3">
  <div class="col-lg-6">
      <div class="card">
          <div class="card-body">
              <div id="circle_radialbar" class="apex-charts" dir="ltr">
                  <apx-chart [series]="customAngleChartOptions.series" [chart]="customAngleChartOptions.chart"
                      [plotOptions]="customAngleChartOptions.plotOptions" [labels]="customAngleChartOptions.labels"
                      [legend]="customAngleChartOptions.legend" [colors]="customAngleChartOptions.colors"
                      [responsive]="customAngleChartOptions.responsive"></apx-chart>
              </div>
          </div>
      </div>
      <!--end card-->
  </div><!-- end col -->
  <div class="col-lg-6">
      <div class="card">
          <div class="card-body">
            <div id="monochrome_pie_chart" class="apex-charts" dir="ltr">
              <apx-chart [series]="semiDonutChartOptions.series" [chart]="semiDonutChartOptions.chart"
                  [labels]="semiDonutChartOptions.labels"
                  [dataLabels]="semiDonutChartOptions.dataLabels" [legend]="semiDonutChartOptions.legend">
              </apx-chart>
            </div>
          </div>
      </div>
      <!--end card-->
  </div><!-- end col -->
</div><!-- end row -->

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body data-table-card">
        <div class="table-responsive mt-4 mt-sm-0 datatables">
          <table class="table align-middle table-nowrap table-check">
            <thead>
              <div class="search-container">
                <span class="form-booking-size form-booking-search">Search: </span>
                <input #inp type="text" id="search" class="form-control form-control-sm ms-2 form-input-bg form-booking-size" [(ngModel)]="searchTerm" (ngModelChange)="search()"  placeholder="Find by name">
              </div>
            <tr class="bg-transparent">
              <th style="width: 30px;">
                <div class="form-check font-size-16">
                  <input type="checkbox" [(ngModel)]="masterSelected" class="form-check-input" id="checkAll" (change)="checkUncheckAll()">
                  <label class="form-check-label" for="checkAll"></label>
                </div>
              </th>
              <th>Booking ID</th>
              <th class="sortable" (click)="sort({ column: 'created_at', direction: isDesc ? 'asc' : 'desc' })">Reservation</th>
              <th class="sortable" (click)="sort({ column: 'checkin_date', direction: isDesc ? 'asc' : 'desc' })">Arrival</th>
              <th>Nights</th>
              <th class="sortable" (click)="sort({ column: 'first_name', direction: isDesc ? 'asc' : 'desc' })">Guest name</th>
              <th>Guests</th>
              <th>Status</th>
              <th style="width: 120px;">Action</th>
            </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="bookings.length > 0; else noBookingsFound">
            <tr *ngFor="let booking of bookings; let index;">
              <td>
                <div class="form-check font-size-16">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="booking.isSelected" id="contacusercheck1">
                  <label class="form-check-label" for="contacusercheck1"></label>
                </div>
              </td>
              <td>{{ booking.id}}</td>
              <td>{{ booking.created_at | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
              <td>{{ booking.checkin_date | date: 'yyyy-MM-dd' }}</td>
              <td>{{ booking.nrOfNights }}</td>
              <td>{{ booking.first_name }} {{ booking.last_name }}</td>
              <td>{{ booking.nrOfGuests }}</td>
              <td style="text-transform: capitalize;">
                <i class="uil-check-circle" *ngIf="booking.status === 'cancelled'" style="color: red;"></i>
                <i class="uil-check-circle" *ngIf="booking.status === 'confirmed'" style="color: green;"></i>
                <i class="uil-check-circle" *ngIf="booking.status === 'pending'" style="color: rgb(228, 156, 12);"></i>
                {{ booking.status }}
              </td>
              <td>
                <ng-container *ngIf="booking.status === 'pending'; else viewButton">
                  <button  type="button" class="btn btn-primary submit_btn" (click)="openbookingDetailsModal(showBookingDetailsModal, index)">
                  Confirm 
                  </button>
                </ng-container>
                <ng-template #viewButton>
                  <button class="btn view-booking-btn" (click)="openbookingDetailsModal(showBookingDetailsModal, index)">View</button>
                </ng-template>
              </td>
              
            </tr>
          </ng-container>
            <ng-template #noBookingsFound>
              <tr>
                <td colspan="8" class="text-center no-bookings">No bookings found</td>
              </tr>
            </ng-template>
            </tbody>
          </table>
        </div>

        <!-- End table -->
        <div class="row justify-content-md-between align-items-md-center mt-2">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
              Showing
              {{(bookings.length > 0 ? 1 : 0)}} to
              {{bookings.length}}
              of {{totalRecords}}
              entries
            </div>
          </div>

          <!-- Pagination -->
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end pagination-rounded">
              <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)">
              </ngb-pagination>
            </div>
          </div>
          <!-- End Pagination -->
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
<ng-template #showBookingDetailsModal role="document" let-modal>
  <div class="modal-header">
    <h5 *ngIf="bookingDetails.status == 'cancelled'" class="modal-title" id="myModalLabel">Booking cancelled</h5>
    <h5 *ngIf="bookingDetails.status == 'pending'" class="modal-title" id="myModalLabel">Booking request pending</h5>
    <h5 *ngIf="bookingDetails.status == 'confirmed'" class="modal-title" id="myModalLabel">Booking confirmed</h5>
    <h5 *ngIf="bookingDetails.status == 'failed'" class="modal-title" id="myModalLabel">Booking failed</h5>
    <button type="button" class="btn-close" aria-label="Close"
    (click)="closeModal(); modal.close('Close click')">
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
          <dt class="col-sm-4 booking-text-color">Guest</dt>
          <dt class="col-sm-8 booking-text-color">{{bookingDetails.salutation}} {{bookingDetails.first_name }} {{bookingDetails.last_name}}</dt>
          <dt class="col-sm-4"></dt>
          <dd class="col-sm-8">{{bookingDetails.phone_number}}</dd>
          <dt class="col-sm-4"></dt>
          <dd class="col-sm-8">{{bookingDetails.email}}</dd>
          <dt class="col-sm-4 fw-normal">Address</dt>
          <dd class="col-sm-8">{{bookingDetails.street}} {{bookingDetails.number}}</dd>
          <dt class="col-sm-4"></dt>
          <dd class="col-sm-8">{{bookingDetails.postcode}} {{bookingDetails.city}}</dd>
          <dt class="col-sm-4 fw-normal">HotelCard</dt>
          <dd class="col-sm-8">{{bookingDetails.card_number}}</dd>
    </div>
    <hr>
    <div class="row">
      <dt class="col-sm-4 booking-text-color">Room</dt>
      <dd class="col-sm-8">{{bookingDetails.room_name}}</dd>
      <dt class="col-sm-4"></dt>
      <dd class="col-sm-8">{{bookingDetails.adults}} Adults,{{bookingDetails.children}} Children,{{bookingDetails.infants}} Infants</dd>
      <dt class="col-sm-4 fw-normal">Check-in</dt>
      <dd class="col-sm-8 d-flex">{{bookingDetails.checkin_date}}</dd>
      <dt class="col-sm-4 fw-normal">Check-out</dt>
      <dd class="col-sm-8 d-flex">{{bookingDetails.checkout_date}}</dd>
    </div>
    <hr>
    <div class="row">
      <dt class="col-sm-8 booking-text-color">Price</dt>
        <table class="table-price">
          <tbody>
            <tr *ngFor="let item of groupPriceDetails(bookingDetails.priceDetails).samePriceDetails">
              <td>
                {{ item.nightValues[0] }} nights
              </td>
              <td class="text-center">
                <del>{{ item.originalPrice }} CHF</del> &nbsp; {{ item.reductionPercentage !== null ? item.reductionPercentage : 'N/A' }}%
              </td>
              
              <td class="update-price-list">{{ item.updatedPrice }} CHF</td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="groupPriceDetails(bookingDetails.priceDetails).differentPriceDetail.length > 0" class="table-price">
          <tbody>
            <tr *ngFor="let item of groupPriceDetails(bookingDetails.priceDetails).differentPriceDetail">
              <!-- <td>
                {{ item.nightValues[1] }} nights
              </td> -->
              <td>
                {{ item.nightValues[0] === 1 ? item.nightValues[0] + ' night' : item.nightValues[1] + ' nights' }}
              </td>
              <td class="text-center position-relative reduced-price">
                <del>{{ item.originalPrice }} CHF</del> &nbsp; {{ item.reductionPercentage !== null ? item.reductionPercentage : 'N/A' }}%
              </td>
              <td class="update-price-list price-list-table">{{ item.updatedPrice }} CHF</td>
            </tr>
          </tbody>
        </table>    
      <dd class="col-sm-3">Breakfast</dd>
      <dd class="col-sm-9 d-flex justify-content-end">{{bookingDetails.wants_breakfast === 1 ? 'Included' : 'Not Included'}}</dd>
        <tr *ngFor="let artical of articlePrice; let index;">
          <div class="row">
            <td class="col-sm-9">{{artical.label}}</td>
            <td class="col-sm-3 d-flex justify-content-end">
              <dd class="d-flex justify-content-end">{{artical.price}}</dd>
            </td>
          </div>
        </tr>
    </div>
    <hr>
    <div class="row">
      <dt class="col-sm-3 fw-bold booking-text-color">Total</dt>
      <dd class="col-sm-9 d-flex justify-content-end fw-bold">{{bookingDetails.price}} {{bookingDetails.currency}}</dd>
    </div>
    <div class="row" *ngIf="bookingDetails.cancelled_reason_description !== null || bookingDetails.comments !== null">
      <hr>
      <dt class="col-sm-6 fw-bold booking-text-color">Guest Comments</dt>
        <div *ngIf="bookingDetails.cancelled_reason_description !== null; else elseBlock">
          <p class="mat-subheading-2 mt-20 mb-20">{{bookingDetails.cancelled_reason_description}} by {{bookingDetails.first_name}} {{bookingDetails.last_name}}</p>
        </div>
      <ng-template #elseBlock>
          <p *ngIf="bookingDetails.cancelled_reason_description !== null" class="mat-subheading-2 mt-20 mb-20">
            This booking has been cancelled on {{bookingDetails.cancelled_reason_description}} by <strong>{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>
          </p>
          <p *ngIf="bookingDetails.comments !== null" class="mat-subheading-2 mt-20 mb-20">
            {{bookingDetails.comments}} by <strong>{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>
          </p>
          <p *ngIf="bookingDetails.hotel.auto_booking === 1" class="mat-subheading-2 mt-20 mb-20">
            This booking has already been automatically confirmed to the customer as you have the <strong>Auto</strong> booking mode activated.
          </p>
          <p *ngIf="bookingDetails.hotel.channel_manager_id" class="mat-subheading-2 mt-20 mb-20">
            This booking has been confirmed automatically because your property is connected to Hotelcard via a channel management system.
          </p>
          <p *ngIf="bookingDetails.hotel.auto_booking === 0 && bookingDetails.confirm_key" class="mat-subheading-2 mt-20 mb-20">
            Please confirm this booking by clicking on confirm button
            <span *ngIf="confirm_success" class="text-success">You have confirmed the booking successfully.</span>
          </p>
      </ng-template>
    </div>
    <p *ngIf="bookingDetails.hotel.auto_booking === null && bookingDetails.hotel.channel_manager_id === null" class="mat-subheading-2 mt-20 mb-20">
      Please confirm the booking made by <strong>{{bookingDetails.first_name}} {{bookingDetails.last_name}}</strong>
      <a href='mailto:{{bookingDetails.email}}?subject=Booking%20confirmation%20from%20{{bookingDetails.room_name}}'>
      {{bookingDetails.email}}</a> within 24 hours. Thank you.
    </p>
    <div>
      <span *ngIf="bookingDetails.status === 'pending'">
        <hr>
        <button  type="button" class="btn btn-primary submit_btn" *ngIf="!confirm_success"
          data-bs-dismiss="modal"
          (click)="modal.close('Close click')"
          [class.spinner]="loading" [disabled]="loading" (click)="confirmBooking({hotel_id: bookingDetails.hotel_id, booking_id: bookingDetails.id, key: bookingDetails.confirm_key})">
          Confirm booking
        </button>
        <button type="button" class="btn btn-outline-primary ms-2 print-btn" (click)="print()" color="warn">
          <span class="material-icons print-icon">print</span> &nbsp;
          <span class="print-text">Print</span>
        </button>
      </span>
      <span *ngIf="bookingDetails.status === 'confirmed'">
        <hr>
        <button type="button" class="btn btn-outline-primary print-btn" (click)="print()" color="warn">
          <span class="material-icons print-icon">print</span> &nbsp;
          <span class="print-text">Print</span>
        </button>
      </span>
      <span *ngIf="bookingDetails.status === 'cancelled'">
        <hr>
        <button type="button" class="btn btn-outline-primary print-btn" (click)="print()" color="warn">
          <span class="material-icons print-icon">print</span> &nbsp;
          <span class="print-text">Print</span>
        </button>
      </span>
    </div>
    <div class="row">
      <div *ngIf="bookingDetails.cancelDetails.cancelAlertLabel != ''">
        <hr>
        <dt *ngIf="bookingDetails.cancelDetails.cancelAlertLabel != ''" class="col-sm-6">Cancelation Details</dt>
        <p class="col-sm-9">{{bookingDetails.cancelDetails.cancelAlertLabel}}</p>
        <hr>
        <div *ngIf="bookingDetails.cancelDetails.showButton == true">
          <button type="button" class="btn btn-danger " (click)="cancelBookingRequest()" color="warn">Cancel booking</button>
        </div>
        <hr>
      </div>
    </div>

  </div>
  <div class="modal-footer">
  </div>
</ng-template>
