<!-- Page Title -->
<app-pagetitle title="Services" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<!-- Services Cards -->
<div
  cdkDropList
  class="list-group"
  (cdkDropListDropped)="onDrop($event)"
  [cdkDropListData]="cards"
>
  <!-- Display only the first card initially -->
  <div *ngIf="cards.length > 0;" class="card mt-3" cdkDrag [cdkDragData]="cards">
    <div class="card-body">
      <!-- Nav Tabs -->
      <div class="row">
        <div class="col-md-10">
          <ul ngbNav #justifyNav="ngbNav" [activeId]="1" class="nav nav-tabs nav-justified">
            <!-- Language Tabs -->
            <li [ngbNavItem]="1">
              <a ngbNavLink>
                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                <span class="d-none d-sm-block text-secondary">Deutsch</span>
              </a>
              <ng-template ngbNavContent>
                <input class="form-control form-input-bg" placeholder="Service description" [value]="hotel?.articles[0].name['de']"/>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>
                <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                <span class="d-none d-sm-block text-secondary">English</span>
              </a>
              <ng-template ngbNavContent>
                <input class="form-control form-input-bg" placeholder="Service description" [value]="hotel?.articles[0].name['en']"/>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>
                <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                <span class="d-none d-sm-block text-secondary">Français</span>
              </a>
              <ng-template ngbNavContent>
                <input class="form-control form-input-bg" placeholder="Service description" [value]="hotel?.articles[0].name['fr']"/>
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>
                <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                <span class="d-none d-sm-block text-secondary">Italiano</span>
              </a>
              <ng-template ngbNavContent>
                <input class="form-control form-input-bg" placeholder="Service description" [value]="hotel?.articles[0].name['it']"/>
              </ng-template>
            </li>
          </ul>

          <!-- Tab Panes -->
          <div class="tab-content p-3 text-muted">
            <div [ngbNavOutlet]="justifyNav"></div>
          </div>
        </div>
        <div class="col-md-2 mt-2 d-flex justify-content-end">
          <!-- Drag Icon -->
          <i class="fa-solid fa-up-down-left-right text-secondary fs-3"></i>
        </div>
      </div>

      <!-- Service Details -->
      <div class="card mt-3">
        <div class="card-body">
          <div class="row">
            <!-- Date Range Picker & Permanent Service Checkbox -->
            <div *ngIf="!hotel.articles[0].time_periods[0].is_permanent_service" class="col-md-5">
              <div class="mb-3">
                <div class="input-container">
                  <input
                    class="form-control"
                    placeholder="Select date range"
                    ngxDaterangepickerMd
                    [(ngModel)]="hotel.articles[0].time_periods[0].dateRange"
                  />
                  <mat-icon class="calendar-icon text-danger">date_range</mat-icon>
                </div>
              </div>
            </div>
            <div class="col-md-5 mt-2">
              <div class="mb-3">
                <input
                  class="form-check-input mr-8 form-input-radio form-input-bg cursor-pointer"
                  type="checkbox"
                  id="service_permanent"
                  name="service_permanent"
                  [(ngModel)]="hotel.articles[0].time_periods[0].is_permanent_service"
                />
                <label class="form-check-label text-muted cursor-pointer" for="service_permanent">
                  &nbsp; This service applies permanently
                </label>
              </div>
            </div>
            <!-- Delete Button -->
            <div class="col-md-2">
              <button
                type="button"
                class="btn text-secondary float-end"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                (click)="openDeleteModal(staticDataModal)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>

          <!-- Days Selection -->
          <div class="row">
            <div class="col-md-6 text-muted">
              <span>On which days should this service apply?</span>
            </div>
            <div class="col-md-6">
              <div class="form-check-inline" *ngFor="let day of daysOfWeek">
                <input
                  class="form-check-input mr-8 form-input-radio form-input-bg cursor-pointer"
                  type="checkbox"
                  [id]="day.id + '_' + 0"
                  [(ngModel)]="hotel?.articles[0]?.time_periods[0]?.deal_days[day.key]"
                  [checked]="hotel?.articles[0]?.time_periods[0]?.deal_days[day.key]"
                />
                <label class="form-check-label text-muted cursor-pointer" [for]="day.id + '_' + 0">
                  &nbsp; {{ day.label }}
                </label>
              </div>
            </div>
          </div>

          <!-- Price Option & Price -->
          <div class="row">
            <div class="col-md-4">
              <div class="mb-3">
                <label class="form-label fs-6 fw-normal text-muted" for="price_option">Price option</label>
                <select class="form-select form-input-bg" id="price_option" name="price_option">
                  <option *ngFor="let priceOption of priceOptions" [value]="hotel?.articles[0].time_periods[0].price_option" [selected]="hotel?.articles[0].time_periods[0].price_option == priceOption.name">
                    {{ hotel?.articles[0].time_periods[0].price_option }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="form-label fs-6 fw-normal text-muted" for="hotelname">Price</label>
                <input type="text" class="form-control form-input-bg" id="hotelname" [value]="hotel?.articles[0]?.time_periods[0].price"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-5">
                <input
                  class="form-check-input mr-8 form-input-radio form-input-bg cursor-pointer"
                  type="checkbox"
                  id="mandatory"
                  name="mandatory"
                  [(ngModel)]="hotel?.articles[0]?.time_periods[0].is_mandatory_service"
                  [checked]="hotel?.articles[0]?.time_periods[0].is_mandatory_service"
                />
                <label class="form-check-label text-muted cursor-pointer" for="mandatory">
                  &nbsp; Mandatory
                </label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <input
                  class="form-check-input mr-8 form-input-radio form-input-bg cursor-pointer"
                  type="checkbox"
                  id="service_room_categories"
                  name="service_room_categories"
                  [(ngModel)]="hotel?.articles[0]?.time_periods[0].allow_all_rooms"
                  [checked]="hotel?.articles[0]?.time_periods[0].allow_all_rooms == 1"
                  (change)="toggleApplicableToAllRooms($event)"
                />
                <label class="form-check-label text-muted cursor-pointer" for="service_room_categories">
                  &nbsp; This service applies to every room category
                </label>
              </div>
              <div class="col-md-12">
                <button
                  *ngFor="let roomCategory of roomCategories"
                  type="button"
                  class="btn mx-2"
                  [ngClass]="{'btn-danger': roomCategory.doubleRoomComfort, 'btn-transparent': !roomCategory.doubleRoomComfort}"
                >
                  {{ roomCategory.label }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Delete Service Button -->
      <button
        type="button"
        class="btn btn-danger float-end rounded-5"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        (click)="openDeleteModal(staticDataModal)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <!-- Additional Cards -->
  <div *ngFor="let card of cards.slice(1); let i = index" class="card mt-3" cdkDrag [cdkDragData]="card">
    <div class="card-body">
      <!-- Nav Tabs -->
      <div class="row">
        <div class="col-md-10">
          <ul ngbNav #justifyNav="ngbNav" [activeId]="1" class="nav nav-tabs nav-justified">
            <!-- Language Tabs -->
            <li *ngFor="let lang of languages; let j = index" [ngbNavItem]="j + 1">
              <a ngbNavLink>
                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                <span class="d-none d-sm-block text-secondary">{{ lang.label }}</span>
              </a>
              <ng-template ngbNavContent>
                <input 
                  class="form-control form-input-bg" 
                  placeholder="Service description" 
                  [id]="'floatingTextarea2_' + (i + 1)"
                  [(ngModel)]="card.descriptions[lang.key]" 
                />
              </ng-template>
            </li>
          </ul>

          <!-- Tab Panes -->
          <div class="tab-content p-3 text-muted">
            <div [ngbNavOutlet]="justifyNav"></div>
          </div>
        </div>
        <div class="col-md-2 mt-2 d-flex justify-content-end">
          <!-- Drag Icon -->
          <i class="fa-solid fa-up-down-left-right text-secondary fs-3"></i>
        </div>
      </div>

      <!-- Service Details -->
      <div class="card mt-3">
        <div class="card-body">
          <div class="row">
            <!-- Date Range Picker & Permanent Service Checkbox -->
            <div *ngIf="!card.isPermanentService" class="col-md-5">
              <div class="mb-3">
                <div class="input-container">
                  <input
                    class="form-control"
                    placeholder="Select date range"
                    ngxDaterangepickerMd
                    [(ngModel)]="card.dateRange"
                  />
                  <mat-icon class="calendar-icon text-danger">date_range</mat-icon>
                </div>
              </div>
            </div>
            <div class="col-md-5 mt-2">
              <div class="mb-3">
                <input
                  class="form-check-input mr-8 form-input-radio form-input-bg cursor-pointer"
                  type="checkbox"
                  [id]="'service_permanent_' + (i + 1)"
                  name="service_permanent_{{i + 1}}"
                  [(ngModel)]="card.isPermanentService"
                />
                <label class="form-check-label text-muted cursor-pointer" [for]="'service_permanent_' + (i + 1)">
                  &nbsp; This service applies permanently
                </label>
              </div>
            </div>
            <!-- Delete Button -->
            <div class="col-md-2">
              <button
                type="button"
                class="btn text-secondary float-end"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                (click)="openDeleteModal(staticDataModal)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>

          <!-- Days Selection -->
          <div class="row">
            <div class="col-md-6 text-muted">
              <span>On which days should this service apply?</span>
            </div>
            <div class="col-md-6">
              <div class="form-check-inline" *ngFor="let day of daysOfWeek">
                <input
                  class="form-check-input mr-8 form-input-radio form-input-bg cursor-pointer"
                  type="checkbox"
                  [id]="day.id + '_' + (i + 1)"
                  [(ngModel)]="card.dealDays[day.key]"
                  [checked]="card.dealDays[day.key]"
                />
                <label class="form-check-label text-muted cursor-pointer" [for]="day.id + '_' + (i + 1)">
                  &nbsp; {{ day.label }}
                </label>
              </div>
            </div>
          </div>

          <!-- Price Option & Price -->
          <div class="row">
            <div class="col-md-4">
              <div class="mb-3">
                <label class="form-label fs-6 fw-normal text-muted" [for]="'price_option_' + (i + 1)">Price option</label>
                <select 
                  class="form-select form-input-bg" 
                  [id]="'price_option_' + (i + 1)"
                  [(ngModel)]="card.priceOption"
                >
                  <option *ngFor="let option of priceOptions" [value]="option.name">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="form-label fs-6 fw-normal text-muted" [for]="'price_' + (i + 1)">Price</label>
                <input 
                  type="text" 
                  class="form-control form-input-bg" 
                  [id]="'price_' + (i + 1)"
                  [(ngModel)]="card.price"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-5">
                <input
                  class="form-check-input mr-8 form-input-radio form-input-bg cursor-pointer"
                  type="checkbox"
                  [id]="'mandatory_' + (i + 1)"
                  name="mandatory_{{i + 1}}"
                  [(ngModel)]="card.isMandatory"
                  [checked]="card.isMandatory"
                />
                <label class="form-check-label text-muted cursor-pointer" [for]="'mandatory_' + (i + 1)">
                  &nbsp; Mandatory
                </label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <input
                  class="form-check-input mr-8 form-input-radio form-input-bg cursor-pointer"
                  type="checkbox"
                  [id]="'allow_all_rooms_' + (i + 1)"
                  name="allow_all_rooms_{{i + 1}}"
                  [(ngModel)]="card.allowAllRooms"
                  [checked]="card.allowAllRooms"
                />
                <label class="form-check-label text-muted cursor-pointer" [for]="'allow_all_rooms_' + (i + 1)">
                  &nbsp; This service applies to every room category
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Delete Service Button -->
      <button
        type="button"
        class="btn btn-danger float-end rounded-5"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        (click)="openDeleteModal(staticDataModal)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- Add Service Button -->
<div class="mt-3">
  <button
    type="button"
    class="btn btn-danger"
    (click)="addCard()"
  >
    Add service
  </button>
</div>

<!-- Modal -->
<div class="col-xl-4 col-sm-6">
  <ng-template #staticDataModal let-modal>
    <div class="modal-header delete-modal-header">
      <h5 class="modal-title mt-0">Delete Service</h5>
      <button
        type="button"
        class="btn-close shadow-none"
        (click)="modal.dismiss('Cross click')"
        aria-hidden="true"
      ></button>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to delete?</p>
    </div>
    <div class="modal-footer delete-modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click')"
      >
        Close
      </button>
      <button
        type="button"
        class="btn btn-primary submit_btn"
        (click)="confirmDelete(modal)"
      >
        Okay
      </button>
    </div>
  </ng-template>
</div>